import React from 'react';
import { Router } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import SignIn from './components/SignIn';
import Blank from './layouts/Blank';
import Default from './layouts/Default';

const Routes = (props) => (
    <Router {...props}>
        <Switch>
            <Blank path="/login" component={Login} />
            <Blank path="/signin" component={SignIn} />
            <Default path="/" component={Home} />
            <Redirect to="/" />
        </Switch>
    </Router>
);

export default Routes;