import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {httpClient} from "../../core/HttpClient";

export default function Home() {
    const { t } = useTranslation();
    console.log('[Home]');
    useEffect(() => {
        httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account')
            .then(res => {
                if(res.data){

                }
            });
    }, []);

    return (
        <div>
            {t('home.title')}
        </div>
    );
}
