import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {Route, Redirect, useHistory} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Alert from '@mui/material/Alert';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CircularProgress from '@mui/material/CircularProgress';
import jwt_decode from "jwt-decode";
import FooterMenu from "../../components/FooterMenu";
import { Context } from "../../core/Context";
import firebase from "../../core/Firebase";
import {httpClient} from "../../core/HttpClient";

const liff = window.liff;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: 0,
    },
    leftMenuButton: {
        marginTop: '1rem',
    },
    title: {
        flexGrow: 1,
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        background: '#efefef'
    },
    showMenu: {
        display: 'inline'
    },
    hideMenu: {
        display: 'none'
    },
    content: {
        marginTop: '10px',
        marginBottom: '80px',
        marginLeft: '10px',
        marginRight: '10px'
    },
    select: {
        paddingRight: '0!important',
        paddingLeft: '20px',
        color: 'white',
        "& .MuiSvgIcon-root": {
            color: "white",
        },
        "& option": {
            color: "black"
        }
    },
    icon:{
        left:-5,
        color: 'white',
    },
    iconOpen:{
        transform:'none'
    },

}));

const DEFAULT_PROFILE_PICTURE = 'https://cdn.foodkub.com/public/assets/empty_user.svg';


const Default = ({role, children}) => {
    const { t } = useTranslation();
    const [context, setContext] = useState({displayName: '', role: role, pictureUrl: DEFAULT_PROFILE_PICTURE, success: false, fail: false});
    const [loading] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const [rewardMessage, setRewardMessage] = React.useState({open: false, status: 'info'});

    useEffect(() => {
        let token = localStorage.getItem('token');
        if(!token){
            history.push('/signin');
        }

        let rider = localStorage.getItem('rider');
        if(rider){
            let _rider = JSON.parse(rider);
            let displayName = _rider.displayName;
            let pictureUrl = _rider.pictureUrl;
            setContext({displayName: displayName, pictureUrl: pictureUrl});
        }


    }, [history]);

    const handleScanQRCode = async() => {
        try{
            const liffId = process.env.REACT_APP_LINE_LIFF_ID;
            await liff.init({ liffId: liffId, withLoginOnExternalBrowser: true }).catch(err=>{throw err});
            if (liff.isLoggedIn()) {
                liff.scanCodeV2().then(result => {
                    if(result){
                        const a = result.value.split('/');
                        const code = a[a.length-1];
                        let data = {
                            code: code
                        };
                        const url = process.env.REACT_APP_API_BASE_URL + '/secure/reward/earn';
                        httpClient.post(url, data)
                            .then(res => {
                                if(res.data){
                                    if(res.data.code === 'SYS_000'){
                                        let key = 'rewards/'+code;
                                        firebase.database().ref(key).set({date: new Date().getTime(), customerId: res.data.customer._id, customerName: res.data.customer.displayName});
                                        history.push(`/hm/rw/${res.data.cid}`);
                                    } else {
                                        setRewardMessage({open: true, status: 'error'});
                                    }
                                }

                            }).catch(e=>{
                            console.log(e);
                        })
                    }
                })
            } else {
                // external browser
                console.log('no logged in');
            }
        }catch(e){console.log(e)}

    };

    const handleCloseRedeemMessage = () => {
        setRewardMessage({...rewardMessage, open: false});
    };

    return (
        <div className='page page-dashboard'>
            <CssBaseline />
            <header>
                <AppBar elevation={0} position="static">
                    <Toolbar>
                        <Avatar edge="start"  alt={context.displayName} src={context.pictureUrl} />
                        <Typography variant="h6" noWrap={true} className={classes.title} ml={1} style={{flexGrow: 1}}>
                            <span>{context.displayName}</span>
                        </Typography>
                        <IconButton edge="end" aria-label="scan"
                                    onClick={handleScanQRCode}>
                            <QrCodeScannerIcon fontSize="large" style={{ color: 'white' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </header>
            <div className={classes.content}>
                <Context.Provider value={[context, setContext]}>{children}</Context.Provider>
            </div>
            <FooterMenu />
            <Backdrop
                style={{
                    zIndex: 999,
                    color: '#fff',
                }}
                open={loading}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
            <Snackbar open={rewardMessage.open}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleCloseRedeemMessage}>
                <Alert variant="filled" onClose={handleCloseRedeemMessage} severity={rewardMessage.status}>
                    {t(`reward.${rewardMessage.status}`)}
                </Alert>
            </Snackbar>
        </div>
    );
};

const DefaultLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                const token = localStorage.getItem('token');
                if (token) {
                    var decoded = jwt_decode(token);
                    return <Default role={decoded.role} ><Component {...props} /></Default>
                } else {
                    return <Redirect to={
                        {
                            pathname: '/login',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }}
        />
    );
};

export default DefaultLayoutRoute;